import React from "react";
import "./TermsAndConditions.css"; // Assumindo que você vai criar um arquivo CSS separado

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Termos e Condições</h1>

      <h2>1. Termos</h2>
      <p>
        Ao acessar o site Settefibra, você concorda em cumprir estes termos de
        serviço, todas as leis e regulamentos aplicáveis e concorda que é
        responsável pelo cumprimento de todas as leis locais aplicáveis. Se você
        não concordar com algum desses termos, está proibido de usar ou acessar
        este site. Os materiais contidos neste site são protegidos pelas leis de
        direitos autorais e marcas comerciais aplicáveis.
      </p>

      <h2>2. Uso de Licença</h2>
      <p>
        É concedida permissão para baixar temporariamente uma cópia dos
        materiais (informações ou software) no site Settefibra, apenas para
        visualização transitória pessoal e não comercial. Esta é a concessão de
        uma licença, não uma transferência de título e, sob esta licença, você
        não pode:
      </p>
      <ul>
        <li>Modificar ou copiar os materiais;</li>
        <li>
          Usar os materiais para qualquer finalidade comercial ou para exibição
          pública (comercial ou não comercial);
        </li>
        <li>
          Tentar descompilar ou fazer engenharia reversa de qualquer software
          contido no site Settefibra;
        </li>
        <li>
          Remover quaisquer direitos autorais ou outras notações de propriedade
          dos materiais;
        </li>
        <li>
          Transferir os materiais para outra pessoa ou 'espelhar' os materiais
          em qualquer outro servidor.
        </li>
      </ul>
      <p>
        Esta licença será automaticamente rescindida se você violar alguma
        dessas restrições e poderá ser rescindida por Settefibra a qualquer
        momento. Ao encerrar a visualização desses materiais ou após o término
        desta licença, você deve apagar todos os materiais baixados em sua
        posse, seja em formato eletrônico ou impresso.
      </p>

      <h2>3. Isenção de responsabilidade</h2>
      <p>
        Os materiais no site da Settefibra são fornecidos 'como estão'.
        Settefibra não oferece garantias, expressas ou implícitas, e, por este
        meio, isenta e nega todas as outras garantias, incluindo, sem limitação,
        garantias implícitas ou condições de comercialização, adequação a um fim
        específico ou não violação de propriedade intelectual ou outra violação
        de direitos.
      </p>
      <p>
        Além disso, o Settefibra não garante ou faz qualquer representação
        relativa à precisão, aos resultados prováveis ou à confiabilidade do uso
        dos materiais em seu site ou de outra forma relacionado a esses
        materiais ou em sites vinculados a este site.
      </p>

      <h2>4. Limitações</h2>
      <p>
        Em nenhum caso o Settefibra ou seus fornecedores serão responsáveis por
        quaisquer danos (incluindo, sem limitação, danos por perda de dados ou
        lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
        incapacidade de usar os materiais em Settefibra, mesmo que Settefibra ou
        um representante autorizado da Settefibra tenha sido notificado
        oralmente ou por escrito da possibilidade de tais danos. Como algumas
        jurisdições não permitem limitações em garantias implícitas, ou
        limitações de responsabilidade por danos consequentes ou incidentais,
        essas limitações podem não se aplicar a você.
      </p>

      <h2>5. Precisão dos materiais</h2>
      <p>
        Os materiais exibidos no site da Settefibra podem incluir erros
        técnicos, tipográficos ou fotográficos. Settefibra não garante que
        qualquer material em seu site seja preciso, completo ou atual.
        Settefibra pode fazer alterações nos materiais contidos em seu site a
        qualquer momento, sem aviso prévio. No entanto, Settefibra não se
        compromete a atualizar os materiais.
      </p>

      <h2>6. Links</h2>
      <p>
        O Settefibra não analisou todos os sites vinculados ao seu site e não é
        responsável pelo conteúdo de nenhum site vinculado. A inclusão de
        qualquer link não implica endosso por Settefibra do site. O uso de
        qualquer site vinculado é por conta e risco do usuário.
      </p>

      <h2>Modificações</h2>
      <p>
        O Settefibra pode revisar estes termos de serviço do site a qualquer
        momento, sem aviso prévio. Ao usar este site, você concorda em ficar
        vinculado à versão atual desses termos de serviço.
      </p>

      <h2>Lei aplicável</h2>
      <p>
        Estes termos e condições são regidos e interpretados de acordo com as
        leis do Settefibra e você se submete irrevogavelmente à jurisdição
        exclusiva dos tribunais naquele estado ou localidade.
      </p>
      <h2>Código de Defesa do Consumidor (CDC)  </h2>
      <p>
        O Código de Defesa do Consumidor (CDC) garante ao consumidor o direito
        de arrependimento em compras realizadas fora do estabelecimento
        comercial, como pela internet, telefone ou catálogos. De acordo com o
        artigo 49 do CDC, o prazo para desistir da compra é de sete dias,
        contados a partir do recebimento do produto ou da assinatura do
        contrato.<br/> Caso opte pelo cancelamento dentro desse prazo, o consumidor
        tem direito ao reembolso integral de qualquer valor pago. O cancelamento
        pode ser solicitado diretamente pelo nosso atendimento, garantindo um
        processo rápido e transparente.
      </p>
    </div>
  );
};

export default TermsAndConditions;
