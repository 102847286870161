import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";
import "./FooterStyle.css";
import { FaFacebook, FaTiktok } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { useState } from "react";
import { MdConstruction } from "react-icons/md";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/FirebaseConfig";
import Loader from "../loader/Loader";
import { Link } from "react-router-dom";
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <span className="footer-subtitle" onClick={decoratedOnClick}>
      {children}
    </span>
  );
}

export default function FooterComponent() {
  const [showTooltipChip, setShowTooltipChip] = useState(false);
  const [isLoading, setLoading] = useState(false);

  async function handleSocialClick(social) {
    setLoading(true);
    try {
      const setSocialClickCount = httpsCallable(
        functions,
        "setSocialClickCount"
      );
      await setSocialClickCount({ platform: social });

      switch (social) {
        case "tiktok":
          window.location.href = "https://www.tiktok.com/@settefibra";
          break;

        case "linkedin":
          window.location.href =
            "https://www.linkedin.com/company/sette-fibra/";
          break;
        case "facebook":
          window.location.href = "https://www.facebook.com/SETTEFIBRA/";
          break;
        case "instagram":
          window.location.href = "https://www.instagram.com/settefibra/";
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error tracking social click:", error);
    }
    return Promise.resolve();
  }

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="row desktop"
        style={{
          position: "relative",
          color: "white",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 250,
          paddingBottom: 200,
          zIndex: 999,
        }}
      >
        <div className="col-1"></div>
        <div className="col-2">
          <div className="row">
            <div className="col-12">
              <img
                alt=""
                src="./assets/logo/SETTE-FIBRA2.png"
                className="se77e-logo"
              />
            </div>
            <div className="col-12 mt-2">
              <span className="marketing1">
                Uma fibra. Sette possibilidades.
              </span>
            </div>
            <div className="col-12 mt-1">
              <span className="icons-footer">
                <span
                  onClick={() => {
                    handleSocialClick("instagram").then(() => {
                      setLoading(false);
                    });
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <AiOutlineInstagram className="icon" />
                </span>
                <span
                  onClick={() => {
                    handleSocialClick("tiktok").then(() => {
                      setLoading(false);
                    });
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <FaTiktok className="icon" />
                </span>
                <span
                  onClick={() => {
                    handleSocialClick("linkedin").then(() => {
                      setLoading(false);
                    });
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <AiFillLinkedin className="icon" />
                </span>
                <span
                  onClick={() => {
                    handleSocialClick("facebook").then(() => {
                      setLoading(false);
                    });
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <FaFacebook className="icon" />
                </span>
              </span>
            </div>
          </div>
          <div className="col-12 mt-2">
            <span className="marketing1">CNPJ: 12.041.618/0001-89</span>
            <br />
            <span className="marketing1">
              Endereço: Rua Waldomiro Correia Neto, 956 Jardim Alexandrina,
              <br />
              Anápolis - GO,
              <br />
              75.060-473.
            </span>
          </div>
        </div>
        <div className="col-3"></div>
        <div className="col-1">
          <div className="row">
            <div className="col-12 mt-4">
              <span className="footer-title">Vantagens</span>
            </div>
            <div className="col-12 mt-2">
              <span
                className="footer-subtitle"
                onMouseEnter={() => setShowTooltipChip(!showTooltipChip)}
                onMouseLeave={() => setShowTooltipChip(!showTooltipChip)}
              >
                Sette Chip
              </span>
              <span
                className={`tooltip-construction ${
                  showTooltipChip ? "show-tooltip" : ""
                }`}
              >
                Em Breve <MdConstruction />
              </span>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="col-1">
          <div className="row">
            <div className="col-12 mt-4">
              <span className="footer-title">Atendimento</span>
            </div>
            <div className="col-12 mt-2">
              <Accordion defaultActiveKey="1">
                <CustomToggle eventKey="0">
                  E-mail <IoIosArrowDown />
                </CustomToggle>
                <Accordion.Collapse eventKey="0">
                  <span
                    className="footer-subtitle2"
                    style={{ marginLeft: 10 }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.se77e.com.br/login"
                  >
                    info@settefibra.com.br
                  </span>
                </Accordion.Collapse>
              </Accordion>
            </div>
            <div className="col-12">
              <a
                className="footer-subtitle"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=556240519377"
              >
                Whatsapp
              </a>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="col-1">
          <div className="row">
            <div className="col-12 mt-4">
              <span className="footer-title">Informações</span>
            </div>
            <div className="col-12 mt-2">
              {/* <a className='footer-subtitle' href={'../../assets/terms/Politica_de_privacidade.pdf'} download="Politica_de_privacidade.pdf">Política</a> */}
              <Link className="footer-subtitle" to={"/terms/privacy-policy"}>
                Política
              </Link>
            </div>
            <div className="col-12">
              {/* <a className='footer-subtitle' href={'../../assets/terms/Termos_e_condições.pdf'} download="Termos_e_condições.pdf">Termos</a> */}
              <Link
                className="footer-subtitle"
                to={"/terms/terms-and-conditions"}
              >
                Termos
              </Link>
            </div>
          </div>
        </div>
        <div className="col-10 offset-1 mt-5">
          <div className="footer-retangle-line"></div>
        </div>
        <div className="col-12 mt-4 text-center">
          <div className="rights-reserved">
            © 2023 SETTE. All rights reserved.
          </div>
        </div>
      </div>
      <div
        className="row mobile"
        style={{
          position: "relative",
          color: "white",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 250,
          paddingBottom: 200,
          zIndex: 999,
        }}
      >
        <div className="col-12">
          <div className="row" style={{ marginLeft: 1 }}>
            <div className="col-12">
              <img
                alt=""
                src="./assets/logo/SETTE-FIBRA2.png"
                className="se77e-logo"
              />
            </div>
            <div className="col-12 mt-2">
              <span className="marketing1">
                Uma fibra. Sette possibilidades.
              </span>
            </div>
            <div className="col-12 mt-2">
              <span className="marketing1">CNPJ: 12.041.618/0001-89</span>
              <br />
              <span className="marketing1">
                Endereço: Rua Waldomiro Correia Neto, 956 Jardim Alexandrina,
                <br />
                Anápolis - GO,
                <br />
                75.060-473.
              </span>
            </div>
            <div className="col-12 mt-1">
              <span className="icons-footer">
                <span
                  onClick={() => {
                    handleSocialClick("instagram");
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  <AiOutlineInstagram className="icon" />
                </span>
                <span
                  onClick={() => {
                    handleSocialClick("tiktok");
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  <FaTiktok className="icon" />
                </span>
                <span
                  onClick={() => {
                    handleSocialClick("linkedin");
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  <AiFillLinkedin className="icon" />
                </span>
                <span
                  onClick={() => {
                    handleSocialClick("facebook");
                  }}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  <FaFacebook className="icon" />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto row">
          <div className="col-4">
            <div className="row">
              <div className="col-12 mt-4">
                <span className="footer-title">Vantagens</span>
              </div>
              <div className="col-12 mt-2">
                <span
                  className="footer-subtitle"
                  onMouseEnter={() => setShowTooltipChip(!showTooltipChip)}
                  onMouseLeave={() => setShowTooltipChip(!showTooltipChip)}
                >
                  Sette Chip
                </span>
                <span
                  className={`tooltip-construction ${
                    showTooltipChip ? "show-tooltip" : ""
                  }`}
                >
                  Em Breve <MdConstruction />
                </span>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col-12 mt-4">
                <span className="footer-title">Atendimento</span>
              </div>
              <div className="col-12 mt-2">
                <Accordion defaultActiveKey="1">
                  <CustomToggle eventKey="0">
                    E-mail <IoIosArrowDown />
                  </CustomToggle>
                  <Accordion.Collapse eventKey="0">
                    <span
                      className="footer-subtitle2"
                      style={{ marginLeft: 5 }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.se77e.com.br/login"
                    >
                      info@settefibra.com.br
                    </span>
                  </Accordion.Collapse>
                </Accordion>
              </div>
              <div className="col-12">
                <a
                  className="footer-subtitle"
                  href="https://api.whatsapp.com/send?phone=556240519377"
                  rel="noreferrer"
                >
                  Whatsapp
                </a>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col-12 mt-4">
                <span className="footer-title">Informações</span>
              </div>
              <div className="col-12 mt-2">
                {/* <a className='footer-subtitle' href={'../../assets/terms/Politica_de_privacidade.pdf'} download="Politica_de_privacidade.pdf">Política</a> */}
                <Link className="footer-subtitle" to={"/terms/privacy-policy"}>
                  Política
                </Link>
              </div>
              <div className="col-12">
                {/* <a className='footer-subtitle' href={'../../assets/terms/Termos_e_condições.pdf'} download="Termos_e_condições.pdf">Termos</a> */}
                <Link
                  className="footer-subtitle"
                  to={"/terms/terms-and-conditions"}
                >
                  Termos
                </Link>
              </div>
            </div>
          </div>
          <div className="col-10 offset-1 mt-5">
            <div className="footer-retangle-line"></div>
          </div>
          <div className="col-12 mt-4 text-center">
            <div className="rights-reserved">
              © 2023 SETTE. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
